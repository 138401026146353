
.card-single{
    display:flex;
    justify-content: space-between;
    background-color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 2px;
    height: 110px;
}

.card-single div:last-child span{
    font-size: 3rem;
    color:var(--main-color);
}

.card-single div:first-child span{
    color:var(--text-grey);
}

.card-single:last-child{
    background: #fff;
}

.card-single:last-child div:last-child span{
    color:#fff;
}

.dashboard_card{
    height:270px,

}

.dashboard_text{
    font-size: 1.5rem;
}