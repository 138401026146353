.green-bg {
    background-color: aqua;
}

.orange-bg{
    background-color: orange;
}

.sick-days-warning {
    background-color: sandybrown !important;
}

.ag-header-cell-text{
    font-size: 1rem !important;
    
}

.ag-row{
    font-size: 1rem !important;
}