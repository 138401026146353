.login2_main{
    background: -webkit-linear-gradient(left,#00c6ff,#1143a6);
    width: 100%;
    min-height:100vh;
    padding:5% 0;
    float:left;
}

.login2_left{
    text-align: center;
    color:#fff;
    margin-top: 4%;
}

.login2_right{
    background: #f8f9fa;
    padding: 40px 60px 40px 60px;
    margin: 5% 15% 5% 15%;
    border-radius: 10px;
    box-shadow: 15px 20px 0px rgba(0,0,0,0.1);
    max-width: 400px;
}

.login2_left img{
    margin-top: 15%;
    margin-bottom: 5%;
    /* -webkit-animation: mover 2s infinite alternate;
    animation: mover 1s infinite alternate; */
}

.login2_right h2{
    margin-bottom:20px; 
    font-weight:800; 
    font-size:30px; 
    color: #1143a6;
}

.login2_right h2:after{
    content:" "; 
    width:100px; 
    height:5px; 
    background:#1143a6; 
    display:block; 
    margin-top:20px; 
    border-radius:3px; 
    margin-left:auto;
    margin-right:auto
}

.btn2-login{
    background: #1143a6; 
    color:#fff; 
    font-weight:600;
}


@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}