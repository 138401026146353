/* PROGRESS BAR */
.progress-bar__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}


.progress-bar {
    margin-top: 10px;
    width: 700px;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    z-index: 5005;

  }
  
  .progress-bar__fill {
    height: 100%;
    background-color: #0253cc;
    transition: width 0.3s ease-in-out;
    width: 0;
    z-index: 5006;
    /* float:center;
    text-align: center; */
  }