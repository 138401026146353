.user_main{
    background: -webkit-linear-gradient(left,#00c6ff,#1143a6);
    width: 100%;
    min-height:100vh;
    padding:5% 0;
    float:left;
}

.user_form_main{
    background: #f8f9fa;
    padding: 40px 60px 40px 60px;
    margin: 0% 15% 0% 15%;
    border-radius: 10px;
    box-shadow: 15px 20px 0px rgba(0,0,0,0.1);
    min-width: 400px;
}

.user_form_main h2{
    margin-bottom:20px; 
    font-weight:800; 
    font-size:30px; 
    color: #1143a6;
}

.user_form_main h2:after{
    content:" "; 
    width:100px; 
    height:5px; 
    background:#1143a6; 
    display:block; 
    margin-top:20px; 
    border-radius:3px; 
    margin-left:auto;
    margin-right:auto
}