.dropdown-scroll{
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.border-yellow{
    border-color: #eee;
}



@media (max-width: 1190px) {
    .hello {
          display: none !important;
        }
}