
.payroll_display{
    display: flex;
    justify-content: center;
}

@media(max-width:1124px){
    .payroll_display{
        display: none;
    }
}