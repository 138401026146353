.scrollbarstyle1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrollbarstyle1::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.scrollbarstyle1::-webkit-scrollbar-thumb
{
	background-color: #bebebe;
	/* background-image: -webkit-gradient(linear, 0 0, 0 100%,
	                   color-stop(.5, rgba(255, 255, 255, .2)),
					   color-stop(.5, transparent), to(transparent)); */
}

.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar
{
	width: 10px;
    height:10px;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb
{
	background-color: #bebebe;
	/* background-image: -webkit-gradient(linear, 0 0, 0 100%,
	                   color-stop(.5, rgba(255, 255, 255, .2)),
					   color-stop(.5, transparent), to(transparent)); */
}

